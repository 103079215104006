.input {
  margin-top: 2vh;
  &-title {
    font-size: 0.7em;
    align-items: flex-end;
  }

  &-error {
    font-size: 0.5em;
    color: red;
    text-transform: capitalize;
  }

  &-box {
    width: 100%;
    border-radius: 7px;
    padding: 10px 0.5vw;
    outline: none;
    border: 2px solid #ddd;
    transition-duration: 0.1s;
  }

  &-box:focus {
    border: 2px solid rgb(121, 145, 253);
  }
}
