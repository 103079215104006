@import '../../scss/mixins.scss';

.whole-container {
  display: flex;
  padding: 2vh 3vw 2vh;
  justify-content: space-evenly;
  min-height: 100vh;
}

.ar {
  flex-direction: row-reverse;
}

.rtl {
  direction: rtl;
}

.container {
  display: flex;
  flex-direction: column;
  width: 75vw;
  background-color: white;
  border-radius: 10px;
  padding: 3vh 4vw;
  // border-top: 10px solid #EB273E;
}

.header-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  width: 100%;
  padding-bottom: 3vh;

  .logo {
    width: 80px;
    aspect-ratio: 1;
    object-fit: contain;
    align-self: flex-start;
  }

  .title-container {
    .black-line {
      width: 5vw;
      height: 0.6vh;
      background-color: black;
      margin: 3vh 0 1vh 0;
    }

    .title {
      font-weight: bold;
      font-size: 1.5em;
    }

    .subtitle {
      font-size: 0.8em;
      font-weight: bold;
    }
  }
}

.other-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 4vh 0;
  border-top: 1px solid #e3e3e3;
}

.total {
  padding: 2vh 0;
  border-top: 1px solid #e3e3e3;
  p {
    font-weight: bold;
  }
}

.right-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2vh;
}

.button-change-language {
  background-color: #494748;
  cursor: pointer;
  margin-top: 1vh;
  height: 40px;
  color: white;
  padding: 1.5vh 2vw;
  font-size: 0.8em;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  min-width: 14vw;
}

.button-downloading {
  background-color: #aaa !important;
  cursor: default !important;
}

@media (max-width: $medium) {
  .header-container {
    flex-direction: column;
  }
  .whole-container {
    flex-direction: column-reverse;
    align-items: center;
    padding: 1vh 0;
  }

  .logo {
    width: 60px;
    margin-bottom: 4vh;
  }

  .container {
    width: 90vw;
  }

  .headers {
    display: block;
    justify-content: center;
    margin-top: 2vh;
    text-align: center;
  }

  .right-container {
    flex-direction: row;
    margin-bottom: 3vh;

    .button {
      width: 40vw;
      height: 40px;
      margin-right: 3vw;
      > img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

@media (max-width: $small) {
  .other-box {
    flex-direction: column;
    margin-bottom: 0;
    padding: 1vh;
  }

  .other-box:nth-of-type(4) {
    flex-direction: column-reverse;
    border: none;
  }
}

@media (min-width: $small) and (max-width: $medium) {
  .button-change-language {
    padding: 10vh 2vw;
  }
}
