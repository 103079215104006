@keyframes loadingRotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10vh 15vw;
}

.loading > b {
  margin-top: 5vh;
  font-size: 2em;
}

.loading-icon {
  transform-origin: 50% 50%;
  animation: loadingRotate 1.5s infinite;
  width: 100px;
}
