@import '../../scss/mixins.scss';

@keyframes loadingRotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.login {
  max-width: 100vw;
  min-height: 100vh;
  display: flex;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 4vw;
  }

  .error {
    color: red;
    font-size: 0.7em;
    min-height: 1em;
    padding: 0.2em 0;
  }

  .login-subcontainer-1 {
    overflow-y: auto;
    align-items: flex-end;
    height: 100vh;
    padding: 5vh 4vw;
    min-height: 100vh;
    .login-text-container {
      width: 50%;
      > p:nth-of-type(1) {
        font-weight: bold;
        font-size: 2.5em;
        margin-bottom: 1vh;
      }
      > p:nth-of-type(2) {
        font-weight: lighter;
        font-size: 0.8em;
        margin-bottom: 2vh;
      }
    }
  }

  .login-subcontainer-2 {
    height: 100vh;
    align-items: flex-start;
    background-color: #eb273e;
    > p {
      font-weight: bold;
      color: white;
      font-size: 1.3em;
    }
    > p:nth-of-type(1) {
      font-size: 2.2em;
    }
  }

  form {
    display: flex;
    flex-direction: column;
  }
}

.login-logo {
  width: 6vw;
  margin-bottom: 2vh;
}

.login-animation {
  animation: loadingRotate 1s infinite;
}

@media screen and (max-width: $medium) {
  .login {
    justify-content: center;
    .login-logo {
      width: 70px;
    }
    .login-subcontainer-1 {
      padding: 0;
      align-items: center;
      width: 100%;
      height: auto;
      .login-text-container {
        width: 70%;
        > p:nth-of-type(1) {
          font-weight: bold;
          font-size: 1.5em;
          margin-bottom: 0.5vh;
        }
        > p:nth-of-type(2) {
          font-weight: lighter;
          font-size: 0.7em;
          margin-bottom: 0.7vh;
        }
      }
    }
    .login-subcontainer-2 {
      display: none;
    }
  }
}
@media screen and (min-width: $small) and (max-width: $medium) {
  .login-subcontainer-1 {
    height: auto;
  }
  .login-text-container {
    height: auto;
    width: 50% !important;
  }

  button,
  .button {
    width: 50%;
    align-self: center;
  }
}
