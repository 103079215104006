.button {
  background-color: #494748;
  cursor: pointer;
  margin-top: 1vh;
  height: 40px;
  color: white;
  padding: 15px 10px;
  font-size: 0.8em;
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  min-width: 14vw;
  border: none;
}

.button-transparent {
  background-color: transparent;
  color: black;
  border: 2px solid black;
  transition-duration: 0.15s;
}

.button-transparent:hover {
  background-color: black;
  color: white;
}
